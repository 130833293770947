import { useStaticQuery, graphql } from "gatsby"
import { useIntl } from "gatsby-plugin-intl"


const useSiteMetadata = () => {
  const data = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          title
          description
        }
      }
    }
  `)
  // return data.site.siteMetadata

  const intl = useIntl()

  return {
    title: intl.formatMessage({ id: "title" }),
    description: intl.formatMessage({ id: "description" }),
  }
}

export default useSiteMetadata
