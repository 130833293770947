import React, { useState } from "react"
import { navigate } from '@reach/router';
import { AnchorLink } from "gatsby-plugin-anchor-links";
import useScrollPosition from '@react-hook/window-scroll'
import { useIntl } from "gatsby-plugin-intl"
//import CookieConsent from 'react-cookie-consent';


import SEO from "./SEO"
import logoImage from "../images/hmd-logo-small-256.png"
//import IconHeart from "../images/icons/heart.svg"

const Layout = ({ children }) => {
  const scrollY = useScrollPosition(60 /*fps*/)
  const intl = useIntl()
  const [open, setOpen] = useState(false);
  const [activeMenuItem, setActiveMenuItem] = useState(false);
  
  const handleMenuClick = (menuItem) =>{
    setActiveMenuItem(menuItem.key); 
    setOpen(!open);

    // Open the url
    if (menuItem.url) {
      window.open(menuItem.url, menuItem.target)
      return;
    }
    // Otherwise navigate to the route
    navigate(`#${menuItem.key}`)
  };
  
  const menuItems = [
    {key:'home', label: intl.formatMessage({ id: "nav.home" })},
    {key:'events-showcase', label: intl.formatMessage({ id: "nav.activities" })},
    {key:'information', label: intl.formatMessage({ id: "nav.information" })},
    {key:'resources', label: intl.formatMessage({ id: "nav.resources" })},
    {key: null, label: intl.formatMessage({ id: "nav.teamsArea" }), url:'https://hmdlineup.web.app/', target:"_blank"},
  ]
  const title = "Hwal Moo Do - Korean Dragon"
  const currentYear = new Date().getFullYear()

  return (
    <>
      <SEO />
      <div className="flex flex-col min-h-screen pt-12 ">
        <header className={`font-semibold z-20 bg-white`}>
        <div className={` fixed top-0 w-full text-gray-700  bg-white  ${scrollY > 10 || open === true ? 'shadow-md' : 'shadow-none'}  dark-mode:text-gray-200 dark-mode:bg-gray-800`}>
          <div className="flex flex-col max-w-screen-xl px-4 mx-auto md:items-center md:justify-between md:flex-row md:px-6 lg:px-8">
            <div className="p-2 flex flex-row items-center justify-between">
              
              <a href="/" className="font-heading font-semibold text-gray-900 uppercase rounded-lg dark-mode:text-white focus:outline-none focus:shadow-outline">
                <img alt="Hwal Moo Do" src={logoImage}  className="w-10 inline mr-3"/>  
                {title}
              
              </a>
              <button className=" md:hidden rounded-lg focus:outline-none focus:shadow-outline" onClick={() => setOpen(!open)}>
                <svg fill="currentColor" viewBox="0 0 20 20" className="w-6 h-6">
                  {!open &&
                    <path fillRule="evenodd" d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM9 15a1 1 0 011-1h6a1 1 0 110 2h-6a1 1 0 01-1-1z" clipRule="evenodd"></path>
                  }
                  {open &&
                    <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path>
                  }
                  </svg>
              </button>
            </div>
            <nav className={`flex-col flex-grow pb-4 md:pb-0 ${open ? 'flex' : 'hidden'} md:flex md:justify-end md:flex-row`}>
              {menuItems.map((menuItem)=>{ 
                 
                  return (
                  <button 
                    key={menuItem.key}
                    className= {`${menuItem.key === activeMenuItem ? 'text-gray-900 bg-gray-200  dark-mode:bg-gray-700' : ''} text-left px-4 py-2 mt-2 text-sm font-semibold bg-transparent rounded-lg dark-mode:bg-transparent dark-mode:hover:bg-gray-600 dark-mode:focus:bg-gray-600 dark-mode:focus:text-white dark-mode:hover:text-white dark-mode:text-gray-200 md:mt-0 md:ml-2 hover:text-gray-900 focus:text-gray-900 hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus:shadow-outline`} 
                    // href={menuItem.url ? menuItem.url:`#${menuItem.key}`}
                    // target={menuItem.target ? menuItem.target:``}
                    onClick={() => {handleMenuClick(menuItem)}}>
                      {menuItem.label}
                    </button>
                  )
              })}

              <div class="relative inline-block text-center">
                <div> 
                  <button
                    onClick={() => {activeMenuItem === 'language' ? setActiveMenuItem("") : setActiveMenuItem("language")}}

                    type="button" 
                    class={`${activeMenuItem === 'language' ? 'text-gray-900 bg-gray-200  dark-mode:bg-gray-700' : ''} inline-flex w-full te px-4 py-2 mt-2 text-sm font-semibold bg-transparent rounded-lg dark-mode:bg-transparent dark-mode:hover:bg-gray-600 dark-mode:focus:bg-gray-600 dark-mode:focus:text-white dark-mode:hover:text-white dark-mode:text-gray-200 md:mt-0 md:ml-2 hover:text-gray-900 focus:text-gray-900 hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus:shadow-outline  transition ease-in-out duration-150`}
                    id="options-menu" aria-haspopup="true" aria-expanded="true">
                     <span className="uppercase">{intl.locale}</span>
                      <svg class="-mr-4 ml-2 h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                        <path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd"/>
                      </svg>
                  </button>
                </div>
                {/* 
                  <!--
                    Dropdown panel, show/hide based on dropdown state.

                    Entering: "transition ease-out duration-100"
                      From: "transform opacity-0 scale-95"
                      To: "transform opacity-100 scale-100"
                    Leaving: "transition ease-in duration-75"
                      From: "transform opacity-100 scale-100"
                      To: "transform opacity-0 scale-95"
                  --> */}
                <div class={`${ activeMenuItem === 'language'?'':'hidden'} origin-top-right absolute mt-2 left-0 ml-4 w-18 rounded-md shadow-lg`}>
                  <div class="rounded-md bg-white">
                    <div class="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                      <a href="/it" class="block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-200 hover:text-gray-900 focus:outline-none focus:bg-gray-200 focus:text-gray-900" role="menuitem">
                        IT
                      </a>
                      <a href="/en" class="block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-200 hover:text-gray-900 focus:outline-none focus:bg-gray-200 focus:text-gray-900" role="menuitem">
                        EN
                      </a>
                    </div>
                  </div>
                </div>
              </div>

            </nav>
          </div>
        </div>
        </header>
        {children}
        <footer className="py-1 text-center bg-gray-200 text-gray-600 text-xs">
         
           Made with 
           <span  className="text-red-500">
             <svg  className=" fill-current w-3 inline mx-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M10 3.22l-.61-.6a5.5 5.5 0 0 0-7.78 7.77L10 18.78l8.39-8.4a5.5 5.5 0 0 0-7.78-7.77l-.61.61z"/></svg>
            </span>by Spiripunzel Productions for HMD Italia
             - <a href="/license">CC BY license</a> - {currentYear}
        </footer>
      </div>
      {/* <CookieConsent
          location="top"
          buttonText="Accept"
          declineButtonText="Decline"
          cookieName="gatsby-gdpr-google-analytics">
          This site uses cookies ...
      </CookieConsent> */}
    </>
  )
}

export default Layout
